import React, { useState } from 'react';
import { object } from 'prop-types';
import root from 'window-or-global';
import { DateTime } from 'luxon';
import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';

import SubscriptionFactory from 'components/subscription-factory';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt, faMoneyCheckEditAlt } from '@fortawesome/pro-light-svg-icons';

import {
  Paper,
  Button,
  Card,
  Hidden,
  CardHeader,
  Box,
  Grid,
  CircularProgress,
  Divider,
  Typography,
  InputLabel,
} from '@material-ui/core';
import useStyles from './styles';

const AccountView = ({ subscription = {}, pricingModels = {} } = {}) => {
  const styles = useStyles();
  const logger = buildLogger();

  const [loading, setLoading] = useState(false);

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  const openPortal = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    try {
      setLoading(true);
      const getCustomerPoratlService = await import('services/customer-portal-service');
      const { getCustomerPortal } = getCustomerPoratlService.default({
        stateContext,
        noticationContext,
      });
      const { url } = await getCustomerPortal();
      if (url) {
        setTimeout(() => {
          root.location.replace(url);
        }, 100);
      } else {
        setLoading(false);
      }
    } catch (err) {
      const trace = await StackTrace.get();
      logger.error(new LoggableError({
        data: { err, caller: 'openPortal' },
        message: 'error trying to get customerportal',
        trace: trace[0],
      }));
    }
  };
  const { model: { name }, stripePeriodEnd, stripeSubscriptionState } = subscription;

  const attentionNeeded = stripeSubscriptionState !== 'beta' && stripeSubscriptionState !== 'active';
  const betaUser = stripeSubscriptionState === 'beta';

  const dateTime = DateTime.fromHTTP(stripePeriodEnd);
  return (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
    <Grid item xs={12} sm={11} md={11} lg={10} xl={6}>
      <Paper className={styles.paper} elevation={4}>
      {betaUser ? (
        <Grid item xs={12} className={styles.fourBotMargin}>
          <Typography
            variant="h4"
            component="h3"
            color="inherit"
            align="center"
            className={styles.headerGreen}>
            Beta User
            <FontAwesomeIcon icon={faMoneyCheckEditAlt} size="lg" className={styles.headerIcon}/>
            <Typography
              variant="caption"
              component="div"
              align="right"
              className={styles.headerSubText}>
              {'As a "Beta User" there\'s nothing for you on this page (at the moment)'}
            </Typography>
          </Typography>
        </Grid>
      ) : (
         <Grid spacing={2}
             container
             direction="row"
             justifyContent="center"
             alignItems="center"
           >
           <Hidden xsDown>
             <Grid item sm={5} className={styles.standardMargin}>
               <SubscriptionFactory{...{
                 subscription: name.toLowerCase(),
                 collapsed: true,
                 models: { ...pricingModels },
                 single: true,
               }}/>
             </Grid>

             <Grid item sm={5} className={styles.standardMargin}>
             <Box
               border={1}
               borderRadius={4}
               className={styles.cardContainer}
               >
               <Card className={styles.card}>
                 <CardHeader
                   avatar={
                     <FontAwesomeIcon icon={faCalendarAlt} className={styles.calendarAvatar}/>
                   }
                   title={dateTime.toLocaleString(DateTime.DATE_FULL)}
                   subheader={'subscription renewal'}
                   aria-label="renewal-date"
                 />
               </Card>
               </Box>
             </Grid>
           </Hidden>

           <Hidden smUp>
           <Grid item xs={12} className={styles.standardMargin}>
             <Box
               border={1}
               borderRadius={4}
               >
               <Grid spacing={0}
                   container
                   direction="row"
                   justifyContent="center"
                   alignItems="center"
                 >
               <Grid item xs={3}>
                   <SubscriptionFactory{...{
                     subscription: name.toLowerCase(),
                     collapsed: true,
                     models: { ...pricingModels },
                     single: true,
                   }}/>
               </Grid>

               <Grid item xs={9}>
                 <Card className={styles.card}>
                   <CardHeader
                     avatar={
                       <FontAwesomeIcon icon={faCalendarAlt} className={styles.calendarAvatar}/>
                     }
                     title={dateTime.toLocaleString(DateTime.DATE_FULL)}
                     subheader={'subscription renewal'}
                     aria-label="renewal-date"
                   />
                 </Card>
               </Grid>
             </Grid>
             </Box>
           </Grid>
           </Hidden>

           <Grid item xs={12} sm={10}>
             <Divider className={styles.dividerMargin}/>
           </Grid>

           <Grid item xs={12} sm={11} md={10} className={styles.fourBotMargin}>
             <Typography
               variant="h4"
               component="h3"
               color="inherit"
               align="center"
               className={styles.headerGreen}>
               {attentionNeeded ? 'Attention Required' : 'Active Subscription'}
               <FontAwesomeIcon icon={faMoneyCheckEditAlt} size="lg" className={styles.headerIcon}/>
               <Typography
                 variant="caption"
                 component="div"
                 align="right"
                 className={styles.headerSubText}>
                 {attentionNeeded
                   ? 'Your subscription needs attention please use please use the Stripe Portal'
                   : 'To update your subscription or your payment details please use the Stripe Portal'}
               </Typography>
             </Typography>
           </Grid>

           <Grid item xs={12} sm={10} md={10}>
             <InputLabel htmlFor="go-to-stripe">Open Stripe Portal</InputLabel>
              <Hidden smUp>
                <Button
                  id='go-to-stripe'
                  variant="contained"
                  color="primary"
                  className={styles.billingButton}
                  onClick={openPortal}
                  type="submit"
                  fullWidth
                  aria-label="load stripe account information"
                  disabled={loading}
                  endIcon={loading
                    ? <CircularProgress className={styles.submitLoading}/>
                    : (
                      <FontAwesomeIcon icon={faFileInvoiceDollar} className={styles.submitIcon}/>
                    )
                  }
                  >
                 Stripe
               </Button>
               </Hidden>
               <Hidden xsDown>
                 <Button
                    id='go-to-stripe'
                    variant="contained"
                    color="primary"
                    className={styles.billingButton}
                    onClick={openPortal}
                    type="submit"
                    fullWidth
                    aria-label="load stripe account information"
                    disabled={loading}
                    endIcon={loading
                      ? <CircularProgress className={styles.submitLoading}/>
                      : (
                        <FontAwesomeIcon icon={faFileInvoiceDollar} className={styles.submitIcon}/>
                      )
                    }
                    >
                   Open Stripe Billing
                 </Button>
               </Hidden>
               <div className={styles.saveButtonTextContainer}>
                 <Typography
                   variant="caption"
                   component="p"
                   align="right"
                   className={styles.headerSubText}>
                     View or manage your billing and subscription
                 </Typography>
               </div>
           </Grid>
         </Grid>
      )}

    </Paper>
    </Grid>
  </Grid>
  );
};

AccountView.propTypes = {
  subscription: object.isRequired,
  pricingModels: object.isRequired,
};

export default AccountView;
